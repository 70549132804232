<template>
    <div class="ml-4">
        <detalhe titulo="ISBP" :size="sizeForm">{{ banco?.ispb }}</detalhe>
        <detalhe titulo="Código" :size="sizeForm">{{ banco?.codigo }}</detalhe>
        <detalhe titulo="Nome" :size="sizeForm">{{ banco?.nomeCompleto }}</detalhe>
    </div>
</template>

<script>
export default {
    props: {
        banco: {
            type: Object,
        },
    },

    data() {
        return {
            sizeForm: '80',
        };
    },
};
</script>