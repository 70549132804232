import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PAGAMENTOS_PORT}${process.env.VUE_APP_API_PATH}/pagamentos`;

export default {
    obterTodas() {
        return axiosJwt.get(`${api}/contas`);
    },

    obterPorId(id) {
        return axiosJwt.get(`${api}/contas/${id}`);
    },

    inserir(data) {
        return axiosJwt.post(`${api}/contas`, data);
    },

    atualizar(data) {
        return axiosJwt.put(`${api}/contas`, data);
    },

    obterBancos() {
        return axiosJwt.get(`${api}/contas/obterbancos`);
    },
};
