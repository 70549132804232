<template>
    <conta-form acao="ATUALIZAR" :cancelar="cancelar" :erros="erros" :conta="conta" @salvar="atualizar($event)" @atualizar="obterConta()"></conta-form>
</template>

<script>
import ContaForm from './ContaForm';
import ContasService from './services';

export default {
    components: {
        ContaForm,
    },

    data() {
        return {
            erros: [],
            conta: null,
        };
    },

    computed: {},

    methods: {
        cancelar() {
            this.$router.push({
                name: `Pagamentos_Contas_Detalhar`,
                params: {
                    id: this.$route.params.id,
                },
            });
        },

        obterConta() {
            this.$store.dispatch('addRequest');
            ContasService.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.conta = response.data;
                } else {
                    this.conta = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar(contaAtualizado) {
            this.$store.dispatch('addRequest');
            let contaAtualizadoDto = {
                contaId: this.$route.params.id,
                descricao: contaAtualizado.descricao,
                banco: contaAtualizado.banco,
                objetoIntegracao: contaAtualizado.objetoIntegracao,
            };
            ContasService.atualizar(contaAtualizadoDto).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Contas',
                        detail: 'Conta atualizada com sucesso',
                        life: 3000,
                    });
                    this.$store.dispatch('setAtualizar', true);
                    this.cancelar();
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        this.obterConta();
    },
};
</script>
