<template>
    <painel titulo="Conta" icone="pi pi-dollar" :refreshFunction="atualizar">
        <erros-box :erros="erros"></erros-box>
        <div class="formgrid grid p-fluid">
            <div class="field col-12">
                <label class="required">Conta</label>
                <InputText type="text" v-model="descricao" :disabled="desabilitaForm" @input="v$.descricao.$touch()" />
                <small class="p-error" v-if="v$.descricao.$error">A conta é obrigatória</small>
            </div>
            <div class="field col-12">
                <label class="required">Banco</label>
                <Dropdown
                    v-model="bancoIspb"
                    :options="bancos"
                    optionLabel="nomeCompleto"
                    optionValue="ispb"
                    :filter="true"
                    placeholder="Selecione o banco"
                    :showClear="true"
                    :filterFields="['iSBP', 'codigo', 'nome', 'nomeCompleto']"
                    v-if="bancos"
                >
                </Dropdown>
            </div>
            <banco :banco="banco" v-if="banco"></banco>
            <div class="field col-12 md:col-12">
                <label class="required">Objeto de Integração</label>
                <Textarea v-model="objetoIntegracao" rows="20" :disabled="desabilitaForm" @input="v$.objetoIntegracao.$touch()" />
                <small class="p-error" v-if="v$.objetoIntegracao.$error">O objeto de integração é obrigatório</small>
            </div>
        </div>
        <slot name="botoes"></slot>
        <Button label="Cancelar" icon="pi pi-times-circle" class="p-button-secondary" @click="cancelar" v-if="!desabilitaForm" />
        <Button
            label="Salvar"
            icon="pi pi-check-circle"
            class="ml-2 p-button-primary"
            @click="confirmarSalvar()"
            v-if="!desabilitaForm"
            :disabled="v$.$invalid"
        />
    </painel>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import ContasService from './services';
import Banco from './Banco.vue';

export default {
    components: {
        Banco,
    },

    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        acao: {
            type: String,
        },

        conta: {
            type: Object,
        },

        erros: {
            type: Array,
        },

        cancelar: {
            type: Function,
        },
    },

    emits: ['salvar', 'atualizar'],

    data() {
        return {
            descricao: null,
            bancoIspb: null,
            objetoIntegracao: null,
            bancos: [],
        };
    },

    validations() {
        return {
            descricao: { required },
            banco: { required },
            objetoIntegracao: { required },
        };
    },

    methods: {
        obterBancos() {
            this.$store.dispatch('addRequest');
            ContasService.obterBancos().then((response) => {
                if (response?.success) {
                    this.bancos = response.data;
                    if (this.acao == 'A') {
                        this.bancoIspb = this.bancos.find((p) => p.ispb == this.conta.banco.ispb)?.ispb;
                    }
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Contas',
                        detail: response.errors[0],
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        preencher() {
            this.descricao = this.conta?.descricao;
            this.bancoIspb = this.conta?.banco.ispb;
            this.objetoIntegracao = this.conta?.objetoIntegracao;
            this.v$.$touch();
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja ${this.acao.toLowerCase()} a conta?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            let contaDto = {
                descricao: this.descricao,
                banco: this.banco,
                objetoIntegracao: this.objetoIntegracao,
            };
            this.$emit('salvar', contaDto);
        },

        atualizar() {
            this.$emit('atualizar');
        },
    },

    created() {
        this.obterBancos();
    },

    computed: {
        desabilitaForm() {
            return this.acao === 'DETALHAR';
        },

        banco() {
            return this.bancos.find((p) => p.ispb == this.bancoIspb);
        },
    },

    watch: {
        conta() {
            this.preencher();
        },
    },
};
</script>
